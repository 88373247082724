<template>
	<div>
		<div class="header">
			<div @click="$router.back(0)" class="titlename">{{titlename}}></div>
			<div class="current">赛事详情</div>
		</div>
		<div class="head">
			<div class="head-title">{{date.matchName}}</div>
			<div style="display: flex;">
				<img class="head-img" :src="image" alt="">
				<div class="head-tetx">
					<div class="head-name">
						<div class="name">ꔷ 赛事项目：</div>
						<div>{{date.matchSport_dictText}}</div>
					</div>
					<!-- <div class="head-name">
						<div class="name">ꔷ 赛事性质：</div>
						<div>{{date.matchProperty_dictText}}</div>
					</div> -->
					<div class="head-name">
						<div class="name">ꔷ 赛事种类：</div>
						<div>{{date.matchCategory_dictText}}</div>
					</div>
					<div class="head-name">
						<div class="name">ꔷ 赛事级别：</div>
						<div>{{date.matchLevel_dictText}}</div>
					</div>
					<div class="head-name">
						<div class="name">ꔷ 赛事时间：</div>
						<div>
							<div v-for="(item,index) in date.matchDate" :key="index">
								<div v-if="item.startDate == item.endDate">{{item.startDate}} </div>
								<div v-else>{{item.startDate}} 至 {{item.endDate}}</div>
							</div>
						</div>
					</div>
					<div class="head-name">
						<div class="name">ꔷ 赛事地点：</div>
						<div>{{date.matchAddressStadium}}</div>
					</div>
					<div class="head-name">
						<div class="name">ꔷ 赛事规模：</div>
						<div>{{date.matchScale}}</div>
					</div>
					<div class="head-name">
						<div class="name">ꔷ 主办单位：</div>
						<div>{{date.hostUnit}}</div>
					</div>
					<div class="head-name">
						<div class="name">ꔷ 承办单位：</div>
						<div>{{date.matchOrganizer}}</div>
					</div>
					<div class="head-name">
						<div class="name">ꔷ 协办单位：</div>
						<div>{{date.matchCoOrganizer}}</div>
					</div>
					<div class="head-name">
						<div class="name">ꔷ 赛事状态：</div>
						<div>{{date.matchRunStatus}}</div>
					</div>
					<p class="head-name" style="color: #646363;">（以上赛事信息由办赛单位提供，不作为赛事审批依据）</p>
				</div>
			</div>
		</div>
		<div class="nav-box">
			<div @click="gettrigger(1)" :class="triggerindex == 1 ? 'nav active' : 'nav' ">
				赛事简介
			</div>
			<div @click="gettrigger(8)" :class="triggerindex == 8 ? 'nav active' : 'nav' ">
				竞赛规程
			</div>
			<div v-for="(item,index) in threeLevel" :key="index"
				@click="gettrigger( Number(index + 2),item.id, 1,index ,item.name,item)"
				:class="triggerindex == Number(index + 2) ? 'nav active' : 'nav' ">
				{{item.name}}
			</div>
			<div @click="gettrigger(7)" :class="triggerindex == 7 ? 'nav active' : 'nav' ">
				赛事成绩
			</div>
		</div>
		<div class="line"></div>

		<div class="display-box">
			<div v-html="date.matchBriefIntroduction" v-if="triggerindex == 1 && date.matchBriefIntroduction"></div>
			<div v-if="triggerindex == 1 && !date.matchBriefIntroduction">暂无信息，请稍后访问</div>

			<div v-html="date.matchRules" v-if="triggerindex == 8 && date.matchRules"></div>
			<div v-if="triggerindex == 8 && !date.matchRules">暂无信息，请稍后访问</div>

			<div v-if="matchdata && triggerindex != 7 && triggerindex != 1 && triggerindex != 8">
				<div class="content">
					<div class="specific" v-if="matchdata.length > 0">
						<ul>
							<li v-for="(item,index) in matchdata" :key="index" v-if="template == 'date_list'">
								<div @click="jump2(item.id)" style="height: 72PX;">
									<div class="news-date">
										<b>{{item.publishTime.split(" ")[0].split("-")[2]}}</b>
										<p>{{item.publishTime.split(" ")[0].split("-")[0]}}-{{item.publishTime.split(" ")[0].split("-")[1]}}
										</p>
									</div>
									<h2>
										{{item.title}}
									</h2>
									<p class="news-content">
										{{getText(item.content)}}
									</p>
								</div>
							</li>
							<div v-if="template == 'picture'" style="display: flex;flex-wrap: wrap;">
								<div class="news_box" v-for="(item,index) in matchdata" :key="index"
									v-if="template == 'picture'">
									<div class="news_item">
										<a @click="jump2(item.id)">
											<img :src="item.imgUrl" v-if="item.imgUrl" />
											<img src="../assets/image/placeholder.png" alt="" v-else>
											<h3>
												<div class="pic-title">
													{{item.title}}
												</div>
											</h3>
										</a>
									</div>
								</div>
							</div>
							<li v-for="(item, index) in matchdata" :key="index" class="nli" @click="jump2(item.id)"
								v-if="template == 'picture_list'">
								<div>
									<div class="img" v-if="item.imgUrl">
										<img :src="item.imgUrl" alt="">
									</div>
									<div class="img" v-else>
										<img src="../assets/image/placeholder.png" alt="">
									</div>
									<h2 class="title2">
										{{item.title}}
									</h2>
									<div class="img_content">
										{{getText(item.content)}}
									</div>
									<span class="date">
										{{item.publishTime.split(" ")[0]}}
									</span>
								</div>
							</li>
							<li v-for="(item,index) in matchdata" :key="index" @click="jump2(item.id)"
								v-if="template == 'common_list'">
								<div style="display: flex;justify-content: space-between;">
									<div class="rounddot">
										<div class="round"></div>
										<a class="roundtitle" @click="jump2(item.id)">
											{{item.title}}
										</a>
									</div>
									<span>[{{item.publishTime | format}}]</span>
								</div>
							</li>
							<div v-if="template == 'picture_column'" style="display: flex;flex-wrap: wrap;">
								<div class="news_box" v-for="(item,index) in matchdata" :key="index"
									v-if="template == 'picture_column'">
									<div class="news_item">
										<a @click="jump2(item.id)">
											<img :src="item.imgUrl" v-if="item.imgUrl" />
											<img src="../assets/image/placeholder.png" alt="" v-else>
											<h3>
												<div class="pic-title">
													{{item.title}}
												</div>
											</h3>
										</a>
									</div>
								</div>
							</div>
							<li v-for="(item,index) in matchdata" :key="index"
								v-if="template != 'picture_column' && template != 'common_list'&& template != 'picture_list'&& template != 'picture'&& template != 'date_list'">
								<div style="display: flex;justify-content: space-between;">
									<div class="rounddot">
										<div class="round"></div>
										<a class="roundtitle" @click="jump2(item.id)">
											{{item.title}}
										</a>
									</div>
									<span>[{{item.publishTime | format}}]</span>
								</div>
							</li>
						</ul>
					</div>
					<div class="specific" v-else>暂无信息，请稍后访问</div>
				</div>


				<!-- <div v-if="name == '视频锦集'" style="width: 100%;display: flex;flex-wrap: wrap;cursor: pointer;" >
					<div v-for="(item,index) in matchdata" :key="index" v-if="matchdata.length > 0" class="content"
						style="margin-bottom: 10PX;" @click="jump2(item.id)">
						<div style="width: 260PX; display: flex;justify-content: center;height: 173PX">
							<div>
								<div v-html="item.content"></div>
								<div v-html="item.title" style="text-align: center;"></div>
							</div>
						</div>
					</div>
					<div v-if="matchdata.length == 0">暂无信息，请稍后访问</div>
				</div>
				<div v-else-if="name == '精彩图集' || name == '精彩瞬间'" style="width: 100%;display: flex;flex-wrap: wrap;">
					<div v-for="(item,index) in matchdata" :key="index" v-if="matchdata.length > 0" class="content" style="margin-bottom: 10PX;">
						<div style="width: 260PX; display: flex;justify-content: center;height: 173PX;cursor: pointer;" @click="jump2(item.id)">
							<div>
								<div>
									<img :src="item.imgUrl" alt="">
								</div>
							</div>
						</div>
					</div>
					<div v-if="matchdata.length == 0">暂无信息，请稍后访问</div>
				</div>
				<div v-else>
					<div v-for="(item,index) in matchdata" :key="index" v-if="matchdata.length > 0" @click="jump2(item.id)" style="cursor: pointer;">
						<div v-html="item.content" style="margin-bottom: 10PX;"></div>
					</div>
					<div v-if="matchdata.length == 0">暂无信息，请稍后访问</div>
				</div> -->


			</div>

			<div v-if="triggerindex == 7">
				<div v-if="distinguish">
					<div class="searchfor">
						<el-select placeholder="请选择" class="level" v-model="groupName" clearable @change="getxm">
							<el-option v-for="item in options2" :key="item.groupName" :label="item.groupName" :value="item.groupName">
							</el-option>
							<template slot="prefix">
								<div style="display: flex;">
									<span class="searchforlogo">
										<img src="../assets/image/group.png" alt="" srcset="">
									</span>
									<span class="title">组别：</span>
								</div>
							</template>
						</el-select>
						<el-select placeholder="请选择" class="level" filterable v-model="projectName" clearable @change="getxm1">
							<el-option v-for="item in options1" :key="item.projectName" :label="item.projectName" :value="item.projectName">
							</el-option>
							<template slot="prefix">
								<div style="display: flex;">
									<span class="searchforlogo">
										<img src="../assets/image/project2.png" alt="" srcset="">
									</span>
									<span class="title">项目：</span>
								</div>
							</template>
						</el-select>
						<el-select placeholder="请选择" class="level" v-model="games" clearable>
							<el-option v-for="item in options3" :key="item.roundName" :label="item.roundName" :value="item.roundName" @change="getxm2">
							</el-option>
							<template slot="prefix">
								<div style="display: flex;">
									<span class="searchforlogo">
										<img src="../assets/image/Event.png" alt="" srcset="">
									</span>
									<span class="title">赛次：</span>
								</div>
							</template>
						</el-select>
						<div class="but" @click="searchfor">
							<div class="img">
								<img src="../assets/image/searchfor.png" alt="">
							</div>
							<div class="text">搜索</div>
						</div>
					</div>

					<div class="table">
						<el-table :data="tableData" v-loading="loading" border style="width: 100%;"
							:header-cell-style="{ background: '#0A6DF3' }">
							<el-table-column type="index" label="序号" align="center" width="55">
							</el-table-column>
							<el-table-column prop="groupName" label="组别" align="center">
							</el-table-column>
							<el-table-column prop="projectName" label="项目" align="center">
							</el-table-column>
							<el-table-column prop="games" label="轮次" align="center">
							</el-table-column>
							<el-table-column prop="name" label="操作" align="center">
								<template slot-scope="scope">
									<el-link type="primary" :underline="false"
										@click="getdetails(scope.row)">查看</el-link>
								</template>
							</el-table-column>
						</el-table>
						<div v-if="tableData.length > 0" style="margin-top: 10PX;">
							赛事成绩由办赛单位提供并保证其真实性，如有问题请与办赛单位联系。</div>
						<div v-if="tableData.length > 0" style="margin-top: 10PX;">
							如有技术问题，请拨打以下电话与网站技术联系。联系电话：010-63827176，咨询时间：工作日（周一至周五）9:00 - 17:00</div>
						<div v-else style="margin-top: 10PX;">赛事成绩未录入，请稍后访问</div>
					</div>
				</div>
				<div v-else class="table">
					<div class="tab_title">{{this.row.matchName}}</div>
					<div class="tab_title_box">
						<div class="tab_title_box1">组别: {{this.row.groupName}}</div>
						<div class="tab_title_box2">项目: {{this.row.projectName}}</div>
						<div class="tab_title_box3">轮次: {{this.row.games}}</div>
					</div>
					<el-table :data="tableData2" v-loading="loading" border style="width: 100%;"
						:header-cell-style="{ background: '#0A6DF3' }">
						<el-table-column prop="ranking" label="名次" align="center" width="55">
						</el-table-column>
						<el-table-column prop="userNames" label="姓名" align="center">
							<template slot-scope="scope">
								<div style="display: flex;    flex-wrap: wrap;justify-content: center">
									<div v-for="(item,index) in scope.row.userNamess" :key="index" style="display: flex;">
										<el-link  type="primary" :underline="false" @click="jump(item)" style="margin-right: 5PX;">{{item}}</el-link>
										<div v-if="index != scope.row.userNamess.length-1">、</div>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="teamName" label="代表队" align="center">
						</el-table-column>
						<el-table-column prop="grade" label="成绩" align="center">
						</el-table-column>
						<el-table-column prop="remarks" label="备注" align="center">
						</el-table-column>
					</el-table>
					<!-- <div class="paging">
						<pagination :total="total3" @currentchange="currentchange" :go="go" :pageSize="pageSize"></pagination>
					</div> -->
					<div class="but2"  @click="returnfor">
						<div class="text2">返回</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue'
	import Meta from 'vue-meta'
	Vue.use(Meta)
	export default {
		name: '',
		components: {},
		data() {
			return {
				id: '',
				date: '',
				triggerindex: 1,
				title: '',
				title1: '',
				title2: '',
				title3: '',
				title4: '',
				title5: '',
				title6: '',
				title7: '0',
				title8: '',
				title9: '',
				title10: '',
				imgs: '',
				total: 0,
				columnId: '',
				columnPid: '',
				threeLevel: [],
				tableData: [],
				options: [],
				matchdata: {},
				options1: [],
				options2: [],
				options3: [],
				name: '',
				distinguish: true,
				difference: true,
				info: {
					matchId: '',
					groupName: '',
					projectName: '',
					games: '',
				},
				sports: '',
				matchName: '',
				tableData2: [],
				tableData3: [],
				image: '',
				titlename: '',
				domain: 'https://t-jsglzx.bgosp.com',
				domain2: 'https://admin.bjcac.org.cn',
				template: '',
				pageNo:1,
				pageSize:10,
				go:10,
				row:{},
				total3:0,
				
				groupName:'',
				projectName:'',
				games:'',
				loading:false,
				navid:''
			}
		},
		filters: {
			format(x) {
				if (x != null && x != '') {
					return x.split(" ")[0]
				}
			}
		},
		created() {
			// this.sportEventList()
			this.imgs = this.$route.query.imgs
			this.id = this.$route.query.id
			this.columnId = this.$route.query.columnId
			this.columnPid = this.$route.query.columnPid
			this.getMatchScoreThreeLinkQuery()
			this.findAllColumn()
			this.eventIntroduction()
			if (this.$route.query.type == 'achievement') {
				this.gradeGrouping()
				this.triggerindex = 7
			}
		},
		methods: {
			getMatchScoreThreeLinkQuery(){
				this.$api.getMatchScoreThreeLinkQuery({matchId:this.$route.query.id}).then(res=>{
					this.options2 = res.data.data.result
				})
			},
			eventIntroduction() {
				this.$api.eventIntroduction(this.id).then(res => {
					res.data.data.result.matchAddressStadium = res.data.data.result.addresses.map(item => {
						return item.matchAddressStadium
					}).join('、')
					if (res.data.data.result.matchImgs && res.data.data.result.matchImgs.length > 0) {
						this.$api.getFile({
							objectName: res.data.data.result.matchImgs[0].filePath
						}).then(res => {
							this.$convert(res.data.data.result.url).then((fileUrl) => {
								this.image = fileUrl
								this.$forceUpdate()
							})
						})
					}
					if (res.data.data.result.matchBriefIntroduction) {
						res.data.data.result.matchBriefIntroduction = this.updatedHtml(res.data.data.result
							.matchBriefIntroduction)
						let imgReg = RegExp(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi); //定义正则，筛选出img元素
						let matchres = res.data.data.result.matchBriefIntroduction.match(imgReg);
						
						if (matchres) {
							matchres.forEach((item) => {
								if (item.indexOf('staticMinioFileCheckSecurity') == -1) {
									return // 说明是历史数据需要返回原来的 不需要改变
								}
								// 从富文本中拿到img标签 并去掉 "/>"
								let _tempStr = item.slice(0, item.length - 2);
								let _index = _tempStr.indexOf('?');
								// 拿到文件的所有旧文件标签 [ img, img, img]
								const srcRegExp = /(\/|http)[^>]+\.(jpg|jpeg|png|gif)/g;
								const _InitialImgSrc = _tempStr.match(srcRegExp);
								// 拿到 minFileName 获取新的src
								if (_InitialImgSrc && _InitialImgSrc.length > 0) {
									// 拿到文件的minFileName  事例： jeditor/90490405b7384040bfdd1d6e7bc14a79_1688977979650OSS.png
									let _fileNameIndex = _InitialImgSrc[0].indexOf('jeditor/');
									let _minFileName = _InitialImgSrc[0].substring(_fileNameIndex,
										_InitialImgSrc[0].length);
									// 拿到文件的minFileName + 后续属性 事例： jeditor/31580d55a8cc489c8d8aa92f59b310c0_1688977994678OSS.png" alt="" width="48" height="48"
									let longHtml = _tempStr.substring(_tempStr.indexOf(_minFileName),
										_tempStr
										.length);
									// 拿到属性 事例： alt="" width="100" height="100"
									let propName = longHtml.substring(longHtml.indexOf(' '), longHtml
										.length);
									// 获取新的验签
									const params = this.$getSign(_minFileName);
									// 拼接img标签
									let _str = _tempStr.substring(0, _index) + '?' + params + '"' +
										propName +
										'/>';
									// 替换img标签
									res.data.data.result.matchBriefIntroduction = res.data.data.result
										.matchBriefIntroduction.replace(item, _str);
										console.log(item,_str)
								}
							});
						}
					}
					if (res.data.data.result.matchRules) {
						res.data.data.result.matchRules = this.updatedHtml(res.data.data.result.matchRules)
						let imgReg = RegExp(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi); //定义正则，筛选出img元素
						let matchres = res.data.data.result.matchRules.match(imgReg);
						if (matchres) {
							matchres.forEach((item) => {
								if (item.indexOf('staticMinioFileCheckSecurity') == -1) {
									return // 说明是历史数据需要返回原来的 不需要改变
								}
								// 从富文本中拿到img标签 并去掉 "/>"
								let _tempStr = item.slice(0, item.length - 2);
								let _index = _tempStr.indexOf('?');
								// 拿到文件的所有旧文件标签 [ img, img, img]
								const srcRegExp = /(\/|http)[^>]+\.(jpg|jpeg|png|gif)/g;
								const _InitialImgSrc = _tempStr.match(srcRegExp);
								// 拿到 minFileName 获取新的src
								if (_InitialImgSrc && _InitialImgSrc.length > 0) {
									// 拿到文件的minFileName  事例： jeditor/90490405b7384040bfdd1d6e7bc14a79_1688977979650OSS.png
									let _fileNameIndex = _InitialImgSrc[0].indexOf('jeditor/');
									let _minFileName = _InitialImgSrc[0].substring(_fileNameIndex,
										_InitialImgSrc[0].length);
									// 拿到文件的minFileName + 后续属性 事例： jeditor/31580d55a8cc489c8d8aa92f59b310c0_1688977994678OSS.png" alt="" width="48" height="48"
									let longHtml = _tempStr.substring(_tempStr.indexOf(_minFileName),
										_tempStr
										.length);
									// 拿到属性 事例： alt="" width="100" height="100"
									let propName = longHtml.substring(longHtml.indexOf(' '), longHtml
										.length);
									// 获取新的验签
									const params = this.$getSign(_minFileName);
									// 拼接img标签
									let _str = _tempStr.substring(0, _index) + '?' + params + '"' +
										propName +
										'/>';
									// 替换img标签
									res.data.data.result.matchRules = res.data.data.result
										.matchRules.replace(item, _str);
								}
							});
						}
					}
					
					
					let exp = /^[+-]?\d*(\.\d*)?(e[+-]?\d+)?$/
					if(exp.test(res.data.data.result.matchScale)){
						res.data.data.result.matchScale = res.data.data.result.matchScale + '人'
					}
					res.data.data.result.matchDate = JSON.parse(res.data.data.result.matchDate)

					this.date = res.data.data.result

					document.title = res.data.data.result.matchName + '-北京市体育竞赛管理和国际交流中心'
					this.$meta().refresh()
				})
			},
			updatedHtml(url) {
				const imgSrcRegex = /<img[^>]+src="([^"]+)"/g;
				let updatedHtml = url;
				let match;
				while ((match = imgSrcRegex.exec(url)) !== null) {
					const imgSrc = match[1];
					if (!/^https?:\/\//i.test(imgSrc)) {
						if (process.env.VUE_APP_BASE_API == 'https://www.bjcac.org.cn') {
							const updatedSrc = `${this.domain2.replace(/\/$/, '')}/${this.removeDotDotSlash(imgSrc)}`;
							updatedHtml = updatedHtml.replace(imgSrc, updatedSrc);
						} else {
							const updatedSrc = `${this.domain.replace(/\/$/, '')}/${this.removeDotDotSlash(imgSrc)}`;
							updatedHtml = updatedHtml.replace(imgSrc, updatedSrc);
						}
					}
				}
				return updatedHtml;
			},
			removeDotDotSlash(url) {
				let updatedUrl = url;
				while (updatedUrl.indexOf('../') !== -1) {
					updatedUrl = updatedUrl.replace('../', '');
				}
				return updatedUrl;
			},
			gettrigger(e, id, type, index, name, item) {
				if (item) {
					this.template = item.template
				}
				this.name = name
				this.triggerindex = e
				this.navid = id
				if (e == 7) {
					this.gradeGrouping()
				} else {
					if (type == 1) {
						let data = {
							id: id,
							matchId: this.id,
							pageNo: 0,
							pageSize: 10
						}
						this.$api.matchArticle(data).then(res => {
							this.matchdata = res.data.data.records
						})
					}
				}
			},
			findAllColumn() {
				this.$api.findAllColumn({
					id: this.columnId,
					pid: this.columnPid
				}).then(res => {
					this.titlename = res.data.data.pid.name
					for (let i in res.data.data.list[0].children) {
						if (res.data.data.list[0].children[i].name == '登记/查询') {
							this.threeLevel = res.data.data.list[0].children[i].children
							if (this.$route.query.type == 'home') {
								for (let x in this.threeLevel) {
									if (this.threeLevel[x].name == '精彩图集' || this.threeLevel[x].name == '精彩瞬间') {
										this.triggerindex = parseInt(x) + 2
										this.gettrigger(parseInt(x) + 2, this.threeLevel[x].id, 1, x, this
											.threeLevel[x].name, this.threeLevel[x])
									}
								}
							}
						}
					}
				})
			},
			getxm(e) {
				if(e.length > 11){
					this.groupName = e.slice(0,10) + '...'
				}else{
					this.groupName = e
				}
				this.info.groupName = e
				
				this.options1 = []
				this.info.projectName = ''
				this.info.games = ''
				this.projectName = ''
				this.games = ''
				for(let i in this.options2){
					if(this.options2[i].groupName == e){
						this.options1 = this.options2[i].projectNameVoList
					}
				}
			},
			getxm1(e){
				if(e.length > 11){
					this.projectName = e.slice(0,10) + '...'
				}else{
					this.projectName = e
				}
				this.info.projectName = e
				
				this.options3 = []
				this.info.games = ''
				this.games = ''
				for(let i in this.options1){
					if(this.options1[i].projectName == e){
						this.options3 = this.options1[i].scoreRoundVoList
					}
				}
			},
			getxm2(e){
				if(e.length > 11){
					this.games = e.slice(0,10) + '...'
				}else{
					this.games = e
				}
				this.info.games = e
			},
			gradeGrouping() {
				this.info.matchId = this.id
				this.loading = true
				this.$api.gradeGrouping(this.info).then(res => {
					this.tableData = res.data.data.result
					this.loading = false
				}).catch(error => {
					this.loading = false
				})
			},
			currentchange(e){
				this.pageNo = e
				this.eventResultsDetails()
			},
			getdetails(row) {
				this.row = row
				this.distinguish = false
				this.eventResultsDetails()
				window.scrollTo(0, 0)
			},
			eventResultsDetails(){
				console.log(this.row)
				let data = {
					matchId : this.id,
					groupName : this.row.groupName,
					projectName : this.row.projectName,
					games : this.row.games,
					projectName : this.row.projectName,
					matchName : this.row.matchName,
					pageNo:this.pageNo,
					pageSize:this.pageSize
				}
				this.loading = true
				this.$api.eventResultsDetails(data).then(res => {
					res.data.data.result.data.forEach(item=>{
						item.userNames = this.$decrypt(item.userNames),
						item.userNamess = item.userNames.split(/[,，、]/)
					})
					this.tableData2 = res.data.data.result.data
					this.total3 = res.data.data.result.count
					this.loading = false
				}).catch(error => {
					this.loading = false
				})
			},
			returnfor() {
				this.distinguish = true
				this.info = {
					matchId: '',
					groupName: '',
					projectName: '',
					games: '',
				}
				this.groupName = ''
				this.projectName = ''
				this.games = ''
				this.gradeGrouping()
				window.scrollTo(0, 0)
			},
			// sportEventList() {
			// 	this.$api.sportEventList({dictValue:'其他'}).then(res => {
			// 		this.options1 = res.data.data.result
			// 	})
			// },
			searchfor() {
				this.info.matchId = this.id
				this.gradeGrouping()
			},
			jump(row) {
				this.$router.push({
					path: '/game',
					query: {
						names: row,
						type: 1,
						refresh: new Date().getTime()
					}
				});
			},
			jump2(id) {
				if(this.navid == 728){
					if (this.$store.state.token == '') {
						this.$confirm('请登录后查看更多信息', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.$store.commit("EXIT_TOKEN")
							this.$router.push({
								path: '/login',
								query: {
									person: true,
									grp: false,
									refresh: new Date().getTime()
								}
							})
						}).catch(() => { });
						return
					}
				}
				let routeData = this.$router.resolve({
					path: '/articleDetails',
					query: {
						id: id,
						refresh: new Date().getTime()
					}
				});
				window.open(routeData.href, '_blank');
			},
			getText(str) {
				if (str == null || str == '') return
				let hasChineseFontFamily = /font-family:\s*[^;]*[\u4E00-\u9FFF]+/g.test(str)
				if (hasChineseFontFamily) {
					str = str.replace(/font-family:\s*[^;]*[\u4E00-\u9FFF]+/g, "font-family: ''");
				}
				let res = str.replace(/[^\u4e00-\u9fa5]/gi, "").replace("微软雅黑", "")
				if (res.length > 80) {
					res = res.slice(0, 80) + '...'
				}
				return res
			},
		}
	}
</script>

<style lang="scss" scoped>
	.header {
		display: flex;
		width: 1200PX;
		margin: 34PX auto 0;
		font-size: 18PX;
		font-weight: 500;
		color: #333333;
		cursor: pointer;
	}

	.current {
		color: #0066FF;
	}

	.active {
		background: #0063FF !important;
		color: #FFFFFF !important;
	}

	.head {
		width: 1200PX;
		margin: 0 auto;
		min-height: 517PX;
		background: #F8FBFF;
		border-radius: 22PX;
		margin-top: 39PX;
		display: flex;
		flex-direction: column;
	}

	.head-img {
		width: 587PX;
		height: 387PX;
		background: #FFFFFF;
		box-shadow: 0PX 6PX 14PX 2PX rgba(0, 99, 255, 0.1);
		border-radius: 19PX;
		margin-top: 14PX;
		margin-left: 53PX;
		min-width:587PX;
	}

	.head-tetx {
		margin-left: 49PX;
		margin-top: 13PX;
		margin-right: 10PX;
	}

	.head-title {
		font-size: 25PX;
		font-weight: 400;
		color: #000000;
		margin-bottom: 20PX;
		width: 1200PX;
		text-align: center;
		margin-top: 20PX;
		padding: 0 10PX;
	}

	.head-name {
		width: 500PX;
		font-size: 17PX;
		font-weight: 400;
		color: #000000;
		margin-bottom: 8PX;
		display: flex;
		.name{
			flex-shrink: 0;
		}
	}

	.nav-box {
		display: flex;
		width: 1200PX;
		margin: 38PX auto 0;
	}

	.nav {
		width: 155PX;
		height: 49PX;
		background: #ECF1FD;
		border-radius: 12PX 12PX 0PX 0PX;
		font-size: 20PX;
		font-weight: bold;
		color: #0063FF;
		margin-right: 7PX;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.line {
		width: 1200PX;
		margin: 0 auto 33PX;
		height: 2PX;
		background: #0063FF;
		border-radius: 1PX;
	}

	.display-box {
		width: 1200PX;
		margin: 0 auto 60PX;
		min-height: 517PX;
		background: #F8FBFF;
		border-radius: 22PX;
		padding: 35PX 21PX;
	}

	.searchfor {
		height: 123PX;
		background: rgba(216, 233, 255, 0.38);
		border-radius: 7PX;
		margin-top: 15PX;
		display: flex;
		padding-left: 15PX;
	}

	.searchfor ::v-deep .el-input__inner {
		width: 305PX;
		height: 43PX;
		background: #FFFFFF;
		border: 1PX solid #003680;
		border-radius: 7PX;
		font-size: 18PX;
		color: #333333;
		padding: 0 15PX 0 120PX;
	}

	.searchfor ::v-deep input::-webkit-input-placeholder {
		color: #333333;
	}

	.searchfor ::v-deep input::-moz-input-placeholder {
		color: #333333;
	}

	.searchfor ::v-deep input::-ms-input-placeholder {
		color: #333333;
	}

	.searchfor ::v-deep .el-select .el-input .el-select__caret {
		color: #191919;
	}

	.searchfor .searchforlogo {
		display: block;
		width: 28PX;
		height: 27PX;
		margin-top: 8PX;
		margin-left: 17PX;
	}

	.searchfor .searchforlogo img {
		width: 100%;
		height: 100%;
	}

	.searchfor .title {
		font-size: 18PX;
		font-weight: bold;
		color: #333333;
		margin-top: 9PX;
		margin-left: 13PX;
	}

	.searchfor .inp ::v-deep .el-input__inner {
		width: 201PX;
		height: 43PX;
	}

	.project {
		margin-top: 41PX;
	}

	.level {
		margin-top: 41PX;
		/* margin-left: 66PX; */
		margin-right: 20PX;
	}

	.area {
		margin-top: 41PX;
		margin-left: 66PX;
	}

	.but {
		width: 150PX;
		height: 43PX;
		background: #164B92;
		border-radius: 9PX;
		display: flex;
		align-items: center;
		margin-top: 41PX;
		cursor: pointer;
	}

	.but .img {
		width: 21PX;
		height: 21PX;
		margin-left: 31PX;
		background: none;
		margin-right: 15PX;
		img{
			width: 100%;
			height: 100%;
			
		}
	}

	.but .text {
		font-size: 19PX;
		font-weight: bold;
		color: #FFFFFF;
		margin-left: 7PX;
		letter-spacing: 4PX;
	}

	.table {
		margin-top: 40PX;
		margin-bottom: 80PX;
	}

	.table ::v-deep .el-table thead {
		color: #fff;
		font-size: 16PX;
		font-weight: 500;
	}

	.table ::v-deep .el-table__header-wrapper {
		border-top-left-radius: 10PX;
		border-top-right-radius: 10PX;
	}

	.table ::v-deep .el-table {
		border-top-left-radius: 10PX;
		border-top-right-radius: 10PX;
	}

	.table ::v-deep .el-table th.el-table__cell.is-leaf {
		border-bottom: 1PX solid #000;
		border-right: 1PX solid #000;
	}

	.table ::v-deep .el-table td,
	.el-table th.is-leaf,
	.el-table--border,
	.el-table--group {
		border-color: black;
	}

	.table ::v-deep .el-table--border::after,
	.el-table--group::after,
	.el-table::before {
		background-color: black;
	}

	.el-select-dropdown__item {
		font-size: 18PX;
	}

	.content {
		width: 260PX;
		margin-right: 25PX;
	}

	.content:nth-child(4n) {
		margin-right: 0;
	}

	.int {
		width: 220PX;
		height: 43PX;
		background: #FFFFFF;
		border: 1PX solid #003680;
		border-radius: 7PX;
		font-size: 18PX;
		color: #333333;
	}

	.int input {
		width: 100%;
		height: 100%;
		background: #fff;
		border-radius: 8PX;
		font-size: 18PX;
		padding: 0 10PX;
	}

	.int ::v-deep .el-input__inner {
		height: 76PX;
		background: #fff;
		border-radius: 8PX;
		font-size: 18PX;
		position: absolute;
		border: none;
		color: #000;
	}

	.tab_title {
		text-align: center;
		font-size: 18PX;
		padding: 18PX;
		width: 1128PX;
		margin: 0 auto;
	}

	.tab_title_box {
		display: flex;
		width: 1128PX;
		margin: 0 auto;
		padding-bottom: 18PX;
	}

	.tab_title_box1 {
		width: 376PX;
		color: #666;
		font-size: 14PX;
		text-align: center;
	}

	.tab_title_box2 {
		width: 376PX;
		color: #666;
		font-size: 14PX;
		text-align: center;
	}

	.tab_title_box3 {
		width: 376PX;
		color: #666;
		font-size: 14PX;
		text-align: center;
	}

	.but2 {
		width: 120PX;
		height: 43PX;
		background: #164B92;
		border-radius: 9PX;
		display: flex;
		align-items: center;
		margin: 41PX auto 0;
		cursor: pointer;
	}

	.but2 .text2 {
		width: 120PX;
		font-size: 19PX;
		font-weight: bold;
		color: #FFFFFF;
		letter-spacing: 4PX;
		text-align: center;
	}

	.titlename:hover {
		color: #0066FF;
	}

	.content {
		width: 1150PX;
		margin: 0 auto;
	}

	.horizontalline {
		border: 1PX dashed #BFBFBF;
		margin-bottom: 23PX;
	}

	.specific {
		font-size: 15PX;
		color: #333333;
		cursor: pointer;
	}

	.specific ul li {
		margin-bottom: 23PX;
	}

	.rounddot {
		display: flex;
		align-items: center
	}

	.round {
		width: 7PX;
		height: 7PX;
		border-radius: 100%;
		background: #000;
		box-shadow: 0 2PX 4PX 0 rgba(0, 0, 0, .2);
		margin-right: 10PX;
	}

	.roundtitle {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		width: 900PX;
		cursor: pointer;
	}

	.paging {
		display: flex;
		align-items: center;
	}

	.paging span {
		margin-right: 5PX;
	}

	::v-deep .el-pager li.active {
		color: #003399;
	}

	.news-date {
		width: 72PX;
		height: 72PX;
		border: 1PX solid #bfd4e9;
		float: left;
		margin-right: 30PX;
		font-family: arial;
		color: #6198e2;
		background: #fff;
		text-align: center;
		transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
	}

	.news-date b {
		height: 43PX;
		line-height: 42PX;
		font-size: 26PX;
	}

	.news-date p {
		font-size: 14PX;
		margin: 0 5PX;
		height: 26PX;
		line-height: 26PX;
		color: #6198e2;
		background: #fff;
		border-top: 1PX solid #dfe7f3;
		transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
	}

	.specific li h2 {
		overflow: hidden;
		font-size: 16PX;
		color: #333;
		font-weight: 700;
		height: 22PX;
		line-height: 22PX;
		margin-bottom: 8PX;
		margin-top: 0;
	}

	.specific li:hover a {
		color: #408ED6;
	}

	.specific li:hover .news-date {
		border: 1PX solid #408ED6;
		background: #408ED6;
		color: #fff;
	}

	.specific li:hover .news-date p {
		color: #fff;
		background: #408ED6;
	}

	.news_item {
		width: 362PX;
		margin-top: 28PX;
		margin-right: 30PX;
	}

	.news_box:nth-child(3n) .news_item {
		margin-right: 0;
	}

	.news_item img {
		width: 100%;
		height: 229PX;
	}

	.news_item h3 {
		font-size: 14PX;
		height: 54PX;
		line-height: 54PX;
		font-weight: 400;
		text-align: center;
		overflow: hidden;
	}

	.news_item:nth-child(3n) {
		margin-right: 0;
	}

	a:hover {
		color: #408ED6;
	}

	.nli {
		margin: 30PX 18PX 0;
		overflow: hidden;
		padding-bottom: 30PX;
		display: block !important;
	}

	.img {
		width: 200PX;
		height: 133PX;
		float: left;
		margin-right: 30PX;
		background: #fff;
	}

	.img img {
		margin: 0 auto;
		width: 200PX;
		height: 133PX;
		border: 0;
		display: block;
	}

	.title2 {
		height: 30PX;
		line-height: 30PX;
		overflow: hidden;
		font-size: 16PX;
		color: #333;
		font-weight: 700;
		margin-bottom: 10PX;
		margin-top: 5PX;
	}

	.date {
		color: #999;
		display: block;
		padding: 10PX 0 0;
	}

	.img_content {
		height: 40PX;
		overflow: hidden;
	}

	ul {
		margin-bottom: 30PX;
	}

	.news-text li:hover h2 {
		color: #408ED6;
	}

	h2 {
		cursor: pointer;
	}

	.pic_item {
		width: 33.3%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 20PX;
	}

	.item-box {
		width: 317PX;
		height: 257PX;
	}

	.pic_title {
		text-align: center;
		margin-top: 20PX;
		cursor: pointer;
		font-size: 16PX;
	}

	.horizon {
		border: 1PX dashed #BFBFBF;
		margin-bottom: 23PX;
		margin-top: 23PX;
		padding: 0 53PX;
		margin-left: 0;
	}
	
	.ie-browser .table ::v-deep .el-table__header{
		width: 100% !important;
	} 
	.ie-browser .table ::v-deep .el-table__body{
		width: 100% !important;
	}
</style>